<template>
  <v-app id="app">
    <div app-data="true">
        <div>
            <router-view></router-view>
        </div>
    </div>
  </v-app>
</template>

<script>
// import LoaderComponent from '@/components/LoaderComponent'
// import { mapActions } from 'vuex'
import { mapActions, mapGetters } from 'vuex'
import { store } from './store'
export default {
  name: 'app',
  components: {
  },
  data () {
    return {
    }
  },
  mounted() {
    window.onpopstate = event => {
      console.log(event)
      if (window.location.pathname == "/"+iamvar_uiContext+"/business/login/stepup") {
        history.back()
        /* if(store.state.cvsstepup.flowType === 'forgot_password') {
          history.back()
        } else if (store.state.cvsstepup.flowType === 'forgot_username') {
          history.back()
        } else if(store.state.cvsstepup.flowType === 'auth') {
          // var mountUrl = store.state.login.urlOnMount
          // window.location.href = mountUrl
          history.back()
        }*/

        
        // history.back()
        /* var mountUrl = store.state.login.urlOnMount
        if(mountUrl.includes("appview=odi")) // odi
          this.$router.push({ name: 'ODILoginComponent', query: { goto: store.state.login.gotoUrl }})
        else if(mountUrl.includes('mainlogin')) //mainlogin
          window.location.href = mountUrl
        else //simplelogin
          this.$router.push({ name: 'SimpleLoginRoot', query: { goto: store.state.login.gotoUrl }}) */// redirect to home, for example
      }
    };

    // Providing access to VpsProfile screen for both vps user and vps wholesale user.
    if (window.location.pathname == "/account/business/login/vpsprofile") {
      if (this.$route.query.client_Id == "VPS" && (this.$route.query.env === "SIT" || this.$route.query.env === "UAT")) {
          this.updateVpsClientId(this.$route.query.client_Id)
          this.udpateVpsCapturedEnv(this.$route.query.env)
          this.$router.push({ name: 'VPSSecurityProfilePage' })
      } else if (this.$route.query.client_Id === "VPSWholeSale" && (this.$route.query.env === "SIT" || this.$route.query.env === "UAT")) {
          this.updateVpsWholeSaleClientId(this.$route.query.client_Id)
          this.udpateVpsWholeSaleCapturedEnv(this.$route.query.env)
          this.$router.push({ name: 'VPSSecurityProfilePage' })
      }
    }
    
  },
  methods: {
    ...mapActions('profile', [
      'udpateVpsCapturedEnv',
      'updateVpsClientId',
      'udpateVpsWholeSaleCapturedEnv',
      'updateVpsWholeSaleClientId'

    ]),
  },
  computed: {
      ...mapGetters('login', [
      'gotoUrl',
      'urlOnMount'
    ])
  }
}
</script>

<style>

.v-application .error {
  background-color: #ed7000 !important; 
  border-color: #ed7000 !important;
}
.v-application .info {
  background-color: #08c !important; 
  border-color: #08c !important;
}

.v-application .success {
  background-color: #00ac3e !important; 
  border-color: #00ac3e !important;
}
</style>
